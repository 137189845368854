input[type="checkbox"] {
   clip: rect(0 0 0 0);
   clip-path: inset(50%);
   height: 1px;
   overflow: hidden;
   position: absolute;
   white-space: nowrap;
   width: 1px;
   font-size: 0.9rem;
 }
 
 .checkbox {
   display: inline-block;
   height: 20px;
   width: 20px;
   background: #fff;
   border: 2px #ddd solid;
   margin-right: 10px;
   transition: .3s;
   flex: 0 0 auto;
 }

 .label {
   display: flex;
   align-items: center;
   cursor: pointer;
 }

 .checkbox--active {
   background-color: blue;
 }