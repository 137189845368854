.section__header {
   position: relative;
   margin-bottom: 50px;
   padding-bottom: 30px;
}

.section__header::after {
   content: '';
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
   bottom: 0;
   width: 60px;
   height: 4px;
   background-color: var(--main-color);
   border-radius: 4px;
}