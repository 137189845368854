.faq {
   padding: 60px 0px;
   overflow: hidden;
}

/* .faq::after {
   width: 100%;
   height: 200px;
   content: '';
   position: absolute;
   left: 0;
   top: 0;
   background-image: url('../../../assets/img/trectangle.svg');
   transform: scale(-1,1);
   z-index: 1;
} */

.wrapper {
   position: relative;
   background-color: var(--link-color);
}

.wrapper::after {
   width: 100%;
   height: 90px;
   content: '';
   position: absolute;
   left: 0;
   top: 0;
   background-image: url('../../../assets/img/trectangle.svg');
   transform: scale(-1,1);
   z-index: 1;
   background-repeat: no-repeat;
   background-size: cover;
}

.wrapper::before {
   width: 100%;
   height: 90px;
   content: '';
   position: absolute;
   left: 0;
   bottom: 0;
   background-image: url('../../../assets/img/trectangle.svg');
   transform: scale(1,-1);
   z-index: 1;
   background-repeat: no-repeat;
   background-size: cover;
}

.inner {
   display: grid;
   grid-template-columns: 1fr;
   grid-gap: 25px;
   padding-bottom: 15px;
}

.help__block {
   max-width: 900px;
   width: 100%;
   margin: 0 auto;
   margin-top: 40px;
   padding: 20px 30px;
   text-align: center;
}

.help__block-text {
   text-align: center;
   font-weight: 600;
   margin-bottom: 25px;  
}