.news {
   padding: 60px 0px;
}

.inner {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   grid-gap: 50px;
}

@media (max-width: 1330px) {
   .inner {
      grid-template-columns: 1fr;
   }
}