.header__link {
   color: var(--text-color);
   font-size: 1.25rem;
   text-decoration: none;
   transition: .3s;
   position: relative;
}

.header__link::after {
   content: '';
   position: absolute;
   left: 0;
   bottom: -3px;
   background-color: var(--link-color);
   width: 0%;
   height: 2px;
   transition: .3s;
   border-radius: 4px;
}

.header__link:hover {
   color: var(--link-color);
}

.header__link:hover.header__link::after {
   width: 100%;
}