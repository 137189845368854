.description {
   padding: 60px 0px;
}

.description__inner {
   display: grid;
   grid-template-columns: 1.5fr 1fr;
   grid-gap: 30px;
   justify-items: center;
   align-items: center;
}

.description__title {
   font-size: 3.9rem;
   color: var(--main-color);
   font-weight: 700;
   margin-bottom: 30px;
}

.description__text {
   font-size: 1.5rem;
   margin-bottom: 50px;
}

.description__btn {
   text-decoration: none;
   padding: 20px 30px;
   font-size: 1.5rem;
   color: #fff;
   overflow: hidden;
}

.description__btn {
   position: relative;
   display: block;
   cursor: pointer;
   outline: none;
   border: 0;
   text-decoration: none;
   background: transparent;
   padding: 0;
   font-size: inherit;
   /* background-color: var(--main-color); */
   color: #fff;
   border: 2px solid var(--main-color);
   z-index: 2;
}

.description__btn {
   width: 22rem;
   height: 63px;
   border-radius: 15px;
}

.circle {
   transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
   position: relative;
   display: block;
   margin: 0;
   width: 4rem;
   height: 68px;
   margin-top: -4px;
   background: var(--link-color);
   border-radius: 0 20px 20px 0px;
   z-index: 0;
}
.icon {
   transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
   position: absolute;
   top: 0;
   bottom: 0;
   margin: auto;
   background: #fff;
}
.arrow {
   transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
   left: 1.15rem;
   width: 1.125rem;
   height: 0.2rem;
   background: none;
}
.arrow::before {
   position: absolute;
   content: '';
   top: -3.8px;
   right: 0.0625rem;
   width: 7px;
   height: 7px;
   border-top: 0.2rem solid #fff;
   border-right: 0.2rem solid #fff;
   transform: rotate(45deg);
}
.button-text {
   transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
   position: absolute;
   top: 50%;
   left: 0rem;
   right: 0;
   transform: translateY(-50%);
   padding: 0.75rem 0;
   margin: 0 0 0 3.5rem;
   font-weight: 700;
   font-size: 1.5rem;
   text-align: center;
   text-transform: uppercase;
   color: var(--main-color);
}
.description__btn:hover .circle {
   width: 102%;
}

.description__btn:hover .icon {
   background: #fff;
   transform: translate(1rem, 0);
}

.description__btn:hover .button-text  {
   color: #fff;
}

.earth {
   max-height: 400px;
}

@media (max-width: 1350px) {
   .description__title {
      font-size: 3rem;
   }
}

@media (max-width: 1050px) {
   .description__title {
      font-size: 2.7rem;
   }
   .description__text {
      font-size: 1.3rem;
   }
}

@media (max-width: 990px) {
   .description__inner {
      grid-template-columns: 1fr;
      grid-gap: 0;
   }

   .earth {
      order: -1;
      min-height: 320px;
   }

   .description {
      padding: 30px 0px;
      height: 87vh;
      display: flex;
      align-items: center;
   }

   .description__title {
      font-size: 4rem;
      text-align: center;
   }
   .description__text {
      font-size: 1.5rem;
      text-align: center;
   }
   .description__btn {
      display: block;
      margin: 0 auto;
   }
   .description__content {
      order: 1;
   }
}

@media (max-width: 890px) {

   .description__title {
      font-size: 3rem;
      text-align: center;
   }
   .description__text {
      font-size: 1.3rem;
      text-align: center;
   }
}

@media (max-width: 680px) {
   .description__title {
      font-size: 2.3rem;
      text-align: center;
   }
   .description__text {
      font-size: 1.3rem;
      text-align: center;
   }
}

@media (max-width: 540px) {
   .description__title {
      font-size: 1.8rem;
      text-align: center;
   }
   .description__text {
      font-size: 1.3rem;
      text-align: center;
   }
}

@media (max-width: 430px) {
   .description__title {
      font-size: 1.5rem;
      text-align: center;
   }
   .description__text {
      font-size: 1.1rem;
      text-align: center;
   }
   .description__btn {
      width: 20rem;
   }
   .description__btn .button-text{
      font-size: 1.3rem;
   }
}

@media (max-width: 385px) {
   .description__title {
      font-size: 1.5rem;
      text-align: center;
   }
   .description__text {
      font-size: 1.1rem;
      text-align: center;
   }
   .description__btn {
      width: 18rem;
   }
   .description__btn .button-text{
      font-size: 1.15rem;
   }
}