.title {
   color: var(--link-color);
   margin-bottom: 20px;
   font-size: 2rem;
   padding-left: 65px;
   position: relative;
}

.title::before {
   content: '';
   position: absolute;
   left: 0;
   top: 50%;
   transform: translateY(-50%);
   width: 50px;
   height: 4px;
   background-color: var(--main-color);
}

/* .section {
   padding-top: 15px;
} */