.header__lang {
   display: flex;
   align-items: center;
   margin-left: 10px;
}

.header__switcher {
   margin: 0px 10px;
   color: var(--main-color);
   text-decoration: none;
   font-weight: 700;
   transition: .3s;
}

.header__switcher:hover {
   color: var(--link-color);
}

.header__separator {
   width: 2px;
   height: 130%;
   border-radius: 2px;
   background-color: var(--text-color);
}

.header__lang-wrapper {
   position: relative;
}



.header__en, .header__ru {
   display: flex;
   align-items: center;
   cursor: pointer;
   font-weight: 700;
   color: var(--main-color);
   transition: .3s;
}

.header__en:hover, .header__ru:hover {
   color: var(--link-color);
}



.header__en img, .header__ru img {
   max-width: 30px;
   width: 100%;
   margin-right: 5px;
}

.header__lang-wrapper:hover .header__lang-list {
   display: grid;
   
}

.header__lang-wrapper:hover .arrow {
   transform: rotate(180deg);
}

.header__lang-list {
   position: absolute;
   top: 150%;
   left: 50%;
   transform: translateX(-50%) translateY(0%);
   width: 100%;
   background-color: #fff;
   border-radius: 10px;
   border: 2px solid var(--main-color);
   padding: 20px;
   width: 200px;
   display: none;
   grid-template-columns: 1fr;
   grid-gap: 20px;
   animation: .3s show ease;
}

.header__lang-list::before {
   content: '';
   left: 50%;
   transform: translateX(-50%);
   top: -20px;
   border: 10px solid transparent;
   border-bottom: 10px solid var(--main-color);
   position: absolute;
}

.header__lang-list::after {
   content: '';
   width: 100%;
   left: 50%;
   transform: translateX(-50%);
   top: -20px;
   height: 40px;
   position: absolute;
}


.header__lang-item {
   width: auto;
   padding: 10px;
   border: 2px solid var(--link-color);
   border-radius: 5px;
   cursor: pointer;
}

.header__lang-item.current {
   background-color: var(--link-color);
   order: 1;
}

.header__lang-item.current span {
   color: #fff;
}

.arrow {
   margin-left: 7px;
   transition: .3s;
}

.header__lang-list.close {
   display: none !important;
}

@media (max-width: 1300px) {
   .header__lang {
      display: none;
   }
}

@media (max-width: 470px) {
   .header__separator {
      height: 100%;
   }
}

@keyframes show {
   from { 
      opacity: 0;
      transform: translateY(-20px) translateX(-50%);
    }
   to {
      opacity: 1;
      transform: translateY(0) translateX(-50%);
   }
 }
