.footer {
   background-color: #333;
   position: relative;
   z-index: 1;
}

.footer__inner {
   padding: 30px 0px;
   display: grid;
   grid-template-columns: 1.5fr 1fr 1fr;
   grid-gap: 30px;
}

.footer__logo {
   max-width: 150px;
   width: 100%;
   margin-bottom: 30px;
   display: block;
}

.footer__copyinfo {
   display: flex;
   flex-direction: column;
   color: var(--footer-text);
}

.footer__copy {
   text-decoration: none;
   color: var(--footer-text);
   margin-bottom: 20px;
   transition: .3s;
}

.footer__copy:hover {
   color: var(--link-color);
}

.footer__text {
   max-width: 70%;
   color: #fff;
}

@media (max-width: 1300px) {
   .footer__inner {
      grid-template-columns: 1fr 1fr 1fr;
   }

   .footer__text {
      max-width: 100%;
      color: #fff;
   }
}

@media (max-width: 990px) {
   .footer__inner {
      grid-template-columns: 1fr;
      justify-content: center;
      text-align: center;
      justify-items: center;
      grid-gap: 0px;
   }

   .footer__logo {
      margin: 0 auto 30px;
   }

   .footer__copyinfo {
      margin-bottom: 30px;
   }
}