.header {
   position: sticky;
   top: 0;
   left: 0;
   width: 100%;
   background-color: #fff;
   z-index: 10;
   box-shadow: 0;
   transition: .3s;
}

.header.sticky {
   box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.4);
}

.header__inner {
   display: flex;
   padding: 15px 0px;
   justify-content: space-between;
   align-items: center;
}

.header__logo {
   max-width: 250px;
   width: 100%;
}

.header__menu-list {
   display: flex;
   margin-right: 20px;
}

.header__menu-list li {
   margin-right: 25px;
}

.header__menu-list li:last-child {
   margin-right: 0;
}

.header__menu {
   display: flex;
   align-items: center;
}

@media (max-width: 1300px) {
   .header__menu-list {
      display: none;
   }
}

@media (max-width: 580px) {
   .header__logo {
      max-width: 200px;
   }
}

@media (max-width: 470px) {
   .header__logo {
      max-width: 150px;
   }
}