.card {
   border-radius: 20px;
   border: 2px solid var(--main-color);
   overflow: hidden;
   box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, .2);
}

.card__inner {
   display: flex;
   align-items: center;
   height: 100%;
}

.card__icon {
   background-color: var(--link-color);
   padding: 20px;
   position: relative;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.card__icon::after {
   content: '';
   position: absolute;
   right: 0;
   bottom: 0;
   height: 100%;
   width: 2px;
   background-color: var(--main-color);
}

.card__icon img {
   max-width: 64px;
   height: auto;
}

.card__info {
   padding: 20px;
   /* background-color: #fff; */
   height: 100%;
   width: 100%;
}

.card__title {
   font-size: 1.7rem;
   margin-bottom: 15px;
   color: var(--main-color);
}

.card__text {
   font-size: 1.4rem;
   color: var(--text-color);
}

@media (max-width: 1425px) {
   .card__text {
      font-size: 1.2rem;
   }
}

@media (max-width: 1275px) {
   .card__text {
      font-size: 1rem;
   }

   .card__title {
      font-size: 1.5rem;
   }
}

@media (max-width: 1125px) {
   .card__text {
      font-size: 1.2rem;
   }

   .card__title {
      font-size: 1.7rem;
   }
}

@media (max-width: 668px) {
   .card__text {
      font-size: 1.1rem;
   }

   .card__title {
      font-size: 1.6rem;
   }
}

@media (max-width: 600px) {
   .card__text {
      font-size: 1.2rem;
   }

   .card__title {
      font-size: 1.4rem;
   }
   .card__info {
      padding: 15px;
   }
}

@media (max-width: 450px) {
   .card__text {
      font-size: 1.1rem;
   }

   .card__title {
      font-size: 1.3rem;
   }

   .card__icon img{
      max-width: 50px;
   }
}