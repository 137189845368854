.tos {
   padding: 60px 0;
}

.inner {
   display: grid;
   grid-template-columns: 2.5fr 1fr;
   grid-gap: 100px;
   align-items: flex-start;
}

.title {
   text-align: center;
   color: var(--main-color);
   font-size: 2.5rem;
   text-transform: uppercase;
   margin-bottom: 60px;
}

.content__title {
   color: var(--link-color);
   margin-bottom: 20px;
   font-size: 2rem;
   padding-left: 65px;
   position: relative;
}

.content__title::before {
   content: '';
   position: absolute;
   left: 0;
   top: 50%;
   transform: translateY(-50%);
   width: 50px;
   height: 4px;
   background-color: var(--main-color);
}

.content p {
   margin-bottom: 20px;
   line-height: 1.3rem;
}

.content a {
   color: var(--link-color);
   text-decoration: none;
   font-weight: 600;
   transition: .3s;
}

.content a:hover {
   color: var(--main-color);
}

.navigation {
   /* border: 2px solid var(--main-color); */
   box-shadow: 0px 0px 15px 1px var(--text-color);
   border-radius: 10px;
   padding: 30px;
}

.navigation__list {
   display: flex;
   flex-direction: column;
}

.navigation__item {
   margin-bottom: 10px;
   color: var(--link-color);
   transition: .3s;
   cursor: pointer;
   font-weight: 600;
}

.navigation__item:hover {
   color: var(--main-color);
}

.navigation__item:last-child {
   margin-bottom: 0;
}

.content ul {
   list-style: none;
}

.content ul li {
   padding-left: 25px;
   margin-bottom: 15px;
   position: relative;
}

.content ul li::before {
   content: '';
   position: absolute;
   left: 0;
   top: 5px;
   height: 9px;
   width: 9px;
   border-radius: 50%;
   background-color: var(--main-color);
}

@media (max-width: 1230px) {
   .content p {
      font-size: 1rem;
   }
   .content__title {
      font-size: 1.4rem;
      padding-left: 40px;
   }
   .content__title::before {
      width: 30px;
   }
   .inner {
      grid-template-columns: 1.5fr 1fr;
   }
   .content li {
      font-size: 1rem;
   }
   .content a {
      font-size: 1rem;
   }
}

@media (max-width: 990px) {
   .inner {
      grid-template-columns: 1fr;
      grid-gap: 45px;
   }

   .navigation { 
      order: -1;
      padding: 30px 60px;
      max-width: 700px;
      margin: 0 auto;
      width: 100%;
   }
   .title {
      font-size: 1.8rem;
   }
}

@media (max-width: 570px) {
   .navigation {
      padding: 30px;
   }
}

@media (max-width: 400px) {
   .title {
      font-size: 1.65rem;
   }
}