.header__mob-btn {
   display: none;
   flex-direction: column;
   justify-content: space-between;
   width: 40px;
   height: 30px;
   cursor: pointer;
   align-items: flex-end;
   margin-left: 20px;
}

.header__mob-btn:hover span {
   background-color: var(--main-color);
}

.header__mob-btn span {
   background-color: var(--link-color);
   height: 4px;
   width: 100%;
   border-radius: 10px;
   transition: .3s;
}

.header__mob-btn span:nth-child(2) {
   width: 30px;
}

@media (max-width: 1300px) {
   .header__mob-btn {
      display: flex;
   }
}

@media (max-width: 390px) {
   .header__mob-btn {
      width: 30px;
      height: 20px;
   }

   .header__mob-btn span {
      background-color: var(--link-color);
      height: 3px;
   }
   
   .header__mob-btn span:nth-child(2) {
      width: 22px;
   }
}