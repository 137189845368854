.button {
   text-decoration: none;
   padding: 20px 35px;
   background-color: var(--main-color);
   color: #fff;
   border-radius: 10px;
   text-transform: uppercase;
   display: inline-block;
   font-weight: 700;
   transition: .3s;
   border: 2px solid var(--main-color);
}

.button:hover {
   background-color: transparent;
   color: var(--main-color);
   transform: scale(1.05);
}