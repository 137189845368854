.card {
   border: 2px solid var(--main-color);
   border-radius: 15px 60px;
   padding: 80px 40px;
   position: relative;
}

.quote {
   position: absolute;
   top: 20px;
   left: 30px;
   color: var(--link-color);
   font-size: 3rem;
}

.title {
   font-size: 1.5rem;
   margin-bottom: 20px;
}

.text {
   margin-bottom: 40px;
}

.btn {
   display: block;
   margin: 0 auto;
   width: fit-content;
}

.title__link {
   text-decoration: none;
   color: var(--main-color);
   transition: .3s;
}

.date {
   margin-bottom: 40px;
   display: flex;
   align-items: center;
   font-weight: 600;

}

.date__icon {
   font-size: 1.4rem;
   margin-right: 10px;
   color: var(--main-color)
}

.title__link:hover {
   color: var(--link-color);
}

@media (max-width: 1330px) {
   .card {
      max-width: 500px;
      margin: 0 auto;
   }
}


@media (max-width: 465px) {
   .title {
      font-size: 1.35rem;
   }
   .text {
      font-size: 1rem;
   }
}

@media (max-width: 400px) {
   .title {
      font-size: 1.2rem;
   }
   .text {
      font-size: 1rem;
   }
   .btn {
      font-size: 1rem;
   }
   .card {
      padding: 70px 30px 50px;
   }
}