.card {
   padding: 40px 30px;
   border: 2px solid var(--link-color);
   border-radius: 15px;
   display: flex;
   flex-direction: column;
   align-items: center;
   box-shadow: 0px 20px 30px 0.5px rgba(50, 50, 50, 0.2);
   margin-top: 100px;
}

.card__period {
   font-size: 1.2rem;
   margin-bottom: 22px;
}

.card__price {
   font-size: 3rem;
   color: rgb(18, 18, 18);
   font-weight: 600;
   margin-bottom: 22px;
}

.card__list {
   display: flex;
   flex-direction: column;
   align-items: center;
   list-style: none;
   margin-bottom: 40px;
}

.card__item {
   margin-bottom: 20px;
}

.card__item:last-child {
   margin-bottom: 0;
}

.card__btn {
   padding: 15px 30px;
   font-size: 1.5rem;
   font-weight: 400;
   text-transform: none;
   border-radius: 10px;
}

.card__price.white {
   color: #fff;
}

.card__btn.white {
   color: var(--main-color);
   background-color: #fff;
   font-weight: 600;
   transition: .3s;
   border: 2px solid #fff !important;
}

.card__btn.white:hover {
   color: #fff;
   background-color: var(--main-color);
}

.card__tag {
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
   top: -24px;
   padding: 14px 20px;
   border-radius: 10px;
   background-color: var(--main-color);
   /* border: 4px solid var(--link-color); */
}

@media (max-width: 1470px) {
   .card {
      margin-top: 0;
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
   }
}

@media (max-width: 480px) {
   .card {
      padding: 30px;
   }
   .card__tag {
      font-size: 1rem;
   }
   .card__btn {
      font-size: 1.2rem;
      margin-top: 10px;
   }
   .card__price {
      font-size: 2.5rem;
   }
   .card__tag {
      padding: 14px 16px;
      font-size: 0.95rem;
   }
   .card__list {
      margin-bottom: 15px;
   }
}

@media (max-width: 400px) {
   .card {
      padding: 25px;
   }

   .card__tag {
      padding: 14px 16px;
      font-size: 0.95rem;
   }
}

@media (max-width: 380px) {
   .card {
      padding: 25px;
   }

   .card__tag {
      font-size: 0.85rem;
      padding: 12px 13px;
      top: -22px;
   }
}