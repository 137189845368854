.title {
   font-size: 2.5rem;
   color: var(--link-color);
   font-weight: 700;
   text-transform: uppercase;
   text-align: center;
   position: relative;
}

@media (max-width: 660px) {
   .title {
      font-size: 1.9rem;
   }
}