.signin {
   padding: 30px 0px;
   /* background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(9,59,185,1) 100%); */
}

.inner {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   padding: 50px;
   border: 2px solid var(--main-color);
   margin: 0 auto;
   border-radius: 20px;
   max-width: 500px;
   width: 100%;
   /* background-color: #fff; */
   transition: .5s;
}

.wrapper__content {
   width: 100%;
   text-align: center;
}

.form {
   width: 100%;
   display: block;
   margin-bottom: 15px;
}

.title {
   font-size: 2rem;
   margin-bottom: 30px;
   color: var(--main-color);
}

input.form__input {
   max-width: 500px;
   width: 100%;
   display: block;
   padding-right: 50px;
   margin: 0 auto;
   transition: .3s;
   margin-bottom: 15px;
}

input.form__input:last-child {
   margin-bottom: 0;
}

.password__inner {
   margin-bottom: 20px;
}

.action {
   display: flex;
   justify-content: space-between;
   margin-bottom: 20px;
   align-items: center;
}

.forget {
   color: var(--link-color);
   text-decoration: none;
   transition: .3s;
   font-weight: 600;
   position: relative;
}

.forget::after {
   position: absolute;
   content: '';
   width: 0%;
   left: 0;
   bottom: -3px;
   height: 2px;
   background-color: var(--dark-color);
   transition: .3s;
}

.forget:hover {
   color: var(--dark-color);
}

.forget:hover::after {
   width: 100%;
}

.btn {
   width: 100%;
   padding: 15px;
   font-size: 1.5rem;
   border: 2px solid var(--main-color);
   background-color: var(--main-color);
   color: #fff;
   border-radius: 10px;
   margin-bottom: 30px;
   cursor: pointer;
   transition: .3s;
}

.btn:hover {
   color: var(--main-color);
   background-color: transparent; 
}

.alternative {
   display: flex;
   justify-content: center;
   align-items: center;
}

.alternative__text {
   margin-right: 10px;
}

.alternative__btn {
   text-decoration: none;
   border: 2px solid var(--main-color);
   border-radius: 5px;
   padding: 10px 15px;
   font-weight: 600;
   color: var(--main-color);
   transition: .3s;
}

.alternative__btn:hover {
   color: var(--link-color);
   border: 2px solid var(--link-color);
}

.password__inner {
   position: relative;
}

.password__btn {
   position: absolute;
   right: 15px;
   top: 50%;
   transform: translateY(-50%);
   cursor: pointer;
}

.password__btn img {
   max-width: 30px;
}

.error {
   display: flex;
   align-items: center;
   margin-top: 10px;
   justify-content: center;
   padding: 10px 10px;
   background-color: #FF6F6F;
   color: #fff;
   border-radius: 5px;
}

.error__text {
   margin-right: 10px;
   font-size: 1rem;
   font-weight: 600;
}

.tooltip__icon {
   max-width: 25px;
}

.btn:disabled {
   cursor: not-allowed;
   background-color: var(--disable-color);
   border-color: var(--disable-color);
   color: var(--disable-text);
}

input.form__input.invalid {
   border-color: #FF6F6F;
   outline: none;
}

.label {
   width: fit-content;
   text-align: left;
   font-size: 15px;

}

.label a {
   color: var(--link-color);
   text-decoration: none;
   transition: .3s;
   font-weight: 600;
   font-size: 16px;
}

.label a:hover {
   color: var(--main-color);
}

.tooltiparea {
   max-width: 350px;
   width: 100%;
   padding: 10px;
   font-size: 1rem
}

@media (max-width: 550px) {
   .label a {
      font-size: 15px;
   }
   .alternative__text {
      font-size: 1rem;
   }
}

@media (max-width: 500px) {
   .inner {
      padding: 30px;
   }

   .title {
      font-size: 1.7rem;
   }

   .tooltiparea {
      max-width: 250px;
   }
}

@media (max-width: 450px) {
   .action {
      flex-direction: column;
      font-size: 1.2rem;
   }
   .forget {
      margin-top: 10px;
   }
}

@media (max-width: 400px) {
   .alternative {
      flex-direction: column;
   }
   .alternative__text {
      margin-right: 0;
      margin-bottom: 10px;
   }
}