.item {
   max-width: 900px;
   width: 100%;
   margin: 0 auto;
   border-radius: 10px;
   /* border: 2px solid var(--main-color); */
   box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, .2);

}

.item__head {
   display: flex;
   padding: 20px 30px;
   align-items: center;
   cursor: pointer;
   border-radius: 10px;
   border: 2px solid var(--main-color);
   background-color: #fff;
   z-index: 2;
   position: relative;
   transition: .3s;
}

.item__head:hover {
   border: 2px solid var(--link-color);
   color: var(--link-color);
   transform: scale(1.02);
}

.item__head:hover .item__icon {
   color: var(--link-color);
}

.item__icon {
   margin-right: 30px;
   color: var(--main-color);
   transition: .3s;
}

.item__icon.open {
   transform: rotate(180deg);
}

.item__title {
   font-size: 1.5rem;
   font-weight: 400;
}

.item__body {
   /* padding: 0px 30px; */
   background-color: #fff;
   margin-top: -15px;
   border: 2px solid var(--main-color);
   border-radius: 10px;
   line-height: 1.2rem;
   max-height: 0;
   overflow: hidden;
   transition: all .5s cubic-bezier(0,1,0,1);
}

.item__body a {
   color: var(--link-color);
   text-decoration: none;
   transition: .3s;
   font-weight: 600;
}

.item__body a:hover {
   color: var(--main-color);
}

.item__body.open {
   height: auto;
   max-height: 99999px;
   transition: all .5s cubic-bezier(1,0,1,0);
}

.item__content {
   padding: 30px 30px 20px;
}

@media (max-width: 990px) {
   .item__title {
      font-size: 1.3rem;
   }  
}

@media (max-width: 500px) {
   .item__title {
      font-size: 1.2rem;
   }  
   .item__content {
      font-size: 1rem;
   }
}