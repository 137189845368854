.ip {
   padding: 60px 0px 20px;
}

.title {
   text-align: center;
   font-size: 2.5rem;
   text-transform: uppercase;
   color: var(--main-color);
   margin-bottom: 30px;
}

.subtitle {
   text-align: center;
   font-size: 1.4rem;
   margin-bottom: 30px;
}

.form {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-bottom: 40px;
}

.form__input-wrapper {
   display: flex;
   align-items: center;
   border-radius: 10px;
   border: 2px solid var(--main-color);
   max-width: 650px;
   width: 100%;
   margin-bottom: 30px;
   overflow: hidden;
   position: relative;
}

.form__input-wrapper::after {
   content: '';
   position: absolute;
   left: -10px;
   height: 100%;
   width: 0%;
   top: 0;
   background: linear-gradient(45deg, var(--link-color), var(--main-color));
   transition: 2s ease;
   border-radius: 0px 10px 10px 0px;

}
.form__input-wrapper.invalid {
   animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

.loading.form__input-wrapper::after {
   width: 105%;
}

.form__protocol {
   background-color: var(--link-color);
   padding: 20px 30px;
   color: #fff;
   font-weight: 600;
   font-size: 20px;
   position: relative;
}

.form__protocol::after {
   content: '';
   position: absolute;
   right: 0;
   top: 0;
   height: 100%;
   width: 2px;
   background-color: var(--main-color);
}

.form__input {
   max-width: 800px;
   width: 100%;
   padding: 10px 20px;
   font-size: 1.4rem;
   height: 100%;
   border: none;
   outline: none;
}

.form__submit {
   padding: 20px 30px;
   background-color: var(--main-color);
   color: #fff;
   border: none;
   border-radius: 10px;
   text-transform: uppercase;
   cursor: pointer;
   font-weight: 700;
   font-size: 1.3rem;
   transition: .3s;
   border: 3px solid var(--main-color);
}

.form__submit:hover {
   background-color: transparent;
   color: var(--main-color);
}

.result {
   text-align: center;
   max-height: 0;
   transition: max-height 2s ease-in;
}

.result p {
   padding: 20px 40px;
   text-align: center;
   color: #fff;
   display: none;
   border-radius: 10px;
   transform: translateY(0%);
   margin-bottom: 20px;
}

.result.good p{
   background-color: #73DD84;
   display: inline-block;
   animation: .7s show ease;
}
.result.bad p{
   background-color: #FF6F6F;
   display: inline-block;
   animation: .7s show ease;
}

.result.good, .result.bad {
   max-height: 9999px;
}

.buy__btn {
   padding: 15px 30px;
   background-color: transparent;
   border: 2px solid var(--main-color);
   margin: 0 auto;
   border-radius: 10px;
   text-decoration: none;
   font-weight: 700;
   color: var(--main-color);
   display: block;
   width: fit-content;
   overflow: hidden;
   position: relative;
   z-index: 3;
   animation: .7s showOpacity ease;
}
.buy__btn span {
   position: relative;
   z-index: 10;
   transition: .3s;
}

.buy__btn:hover span {
   color: #fff;
}


.buy__btn::after {
   content: '';
   position: absolute;
   left: 0;
   top: 0;
   width: 0%;
   height: 200px;
   background-color: var(--link-color);
   transform: skew(15deg);
   transition: .5s;
   z-index: 0;
}

.buy__btn:hover::after {
   width: 200%;
}

@keyframes show {
   from { 
      opacity: 0;
      transform: translateY(-20px);
    }
   to {
      opacity: 1;
      transform: translateY(0);
   }
 }

 @keyframes showOpacity {
   from { 
      opacity: 0;
    }
   to {
      opacity: 1;
   }
 }

@keyframes loader {
   0%{
      width: 0%;
  }
  100%{
      width: 100%;
  }
}

@keyframes shake {
   10%, 90% {
     transform: translate3d(-1px, 0, 0);
   }
   
   20%, 80% {
     transform: translate3d(2px, 0, 0);
   }
 
   30%, 50%, 70% {
     transform: translate3d(-4px, 0, 0);
   }
 
   40%, 60% {
     transform: translate3d(4px, 0, 0);
   }
 }

@media (max-width: 680px) {
   .title {
      font-size: 2rem;
   }
   .subtitle {
      font-size: 1.2rem;
   }
}

@media (max-width: 530px) {
   .title {
      font-size: 1.7rem;
   }
   .form__submit {
      font-size: 1.2rem;
   }
}

@media (max-width: 420px) {
   .title {
      font-size: 1.5rem;
   }
   .subtitle {
      font-size: 1.1rem;
   }
   .form__submit {
      font-size: 1.2rem;
   }
   .form__protocol {
      padding: 20px 15px;
   }
}