.sigin {
   padding: 10px 20px;
   border-radius: 10px;
   border: 2px solid var(--main-color);
   font-size: 20px;
   text-decoration: none;
   color: var(--main-color);
   font-weight: 700;
   text-transform: uppercase;
   position: relative;
   z-index: 2;
   overflow: hidden;
   display: block;
}

.sigin::after {
   content: '';
   position: absolute;
   left: -40px;
   top: 0;
   width: 0%;
   height: 200px;
   background-color: var(--link-color);
   transform: skew(15deg);
   transition: .5s;
   z-index: -1;
}

.sigin:hover::after {
   width: 200%;
}

.sigin span {
   position: relative;
   z-index: 3;
   transition: .3s;
}

.sigin:hover span {
   color: #fff;
}

.signin__wrapper {
   margin-left: 30px;
}

@media (max-width: 1300px) {
   .signin__wrapper {
      display: none;
   }
}