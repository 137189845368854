.link__btn {
   margin-bottom: 10px;
    color: var(--link-color);
    transition: .3s;
    cursor: pointer;
    font-weight: 600;
    display: block;
}

.link__btn:hover {
   color: var(--main-color);
}

.link__past {
   color: var(--footer-text);
}

.link__active {
   color: var(--main-color);
}


.child {
   margin-left: 20px;
}