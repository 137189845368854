.price {
   padding: 60px 0px;
}

.inner {
   display: grid;
   grid-template-columns: 1fr 0.95fr 1fr;
   grid-gap: 100px;
   align-items: flex-start;
   padding: 30px 0px;
}

.best__option {
   margin-top: 30px !important;
   box-shadow: 0px 20px 40px 4px rgba(0, 0, 0, 0.3) !important;
   /* transform: translateZ(60px) scale(1.1); */
   background-color: var(--link-color);
   color: #fff;
   position: relative;
   border: 2px solid var(--main-color) !important;
}

.best__option-price {
   color: #fff;
}


@media (max-width: 1570px) {
   .inner {
      grid-gap: 55px;
      padding: 0px 20px;
   }
}

@media (max-width: 1470px) {
   .best__option  {
      order: -1;
      grid-column-start: 1;
      grid-column-end: 3;
      max-width: 500px;
      width: 100%;
      padding: 40px !important;
      margin: 0 auto;
   }

   .inner {
      /* justify-items: center; */
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 80px;
   }
}

@media (max-width: 1000px) {
   .best__option  {
      order: 0;
      grid-column-start: 1;
      grid-column-end: 1;
      max-width: 400px;
      width: 100%;
      padding: 40px !important;
      margin: 0 auto;
      transform: scale(1);
   }

   .inner {
      /* justify-items: center; */
      grid-template-columns: 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 60px;
   }
}

@media (max-width: 400px) {
   .best__option {
      padding: 25px !important;
   }
}
