.logotype {
   padding: 60px 0px;
}

.inner {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 40px 10px;
   border-top: 2px solid rgba(27, 156, 252, 0.5);
   border-bottom: 2px solid rgba(27, 156, 252, 0.5);
   box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, .2);
}

.inner img {
   height: 55px;
   width: auto;
   object-fit: contain;
   margin-right: 80px;
   display: block;
}

.inner img.visa {
   height: 35px;
}

.inner img.mir {
   height: 35px;
}

.inner img:last-child {
   margin: 0;
}

@media (max-width: 1250px) {
   .inner img {
      margin-right: 50px;
   }
}

@media (max-width: 1040px) {
   .inner img {
      margin-right: 40px;
   }
}


@media (max-width: 966px) {

   .inner {
      flex-direction: column;
   }
   .inner img{
      margin-right: 0;
      margin-bottom: 35px;
   }

   .logotype {
      padding: 30px 0px;
   }
}