.wrapper {
   padding: 60px 0;
}

.title {
   text-align: center;
   color: var(--main-color);
   font-size: 2.5rem;
   text-transform: uppercase;
   margin-bottom: 60px;
}

.inner {
   display: flex;
   align-items: flex-start;
}

.sidebar {
   max-width: 260px;
   width: 100%;
   position: sticky;
   top: 160px;
   /* border-left: 2px solid var(--main-color); */
   padding-left: 10px;
   margin-right: 60px;
   
}

.scroll {
   overflow-y: scroll;
   max-height: 70vh;
   padding-right: 15px;
}

.scroll::-webkit-scrollbar-thumb {
   background-color: var(--main-color); 
   border-radius: 20px;
 }

 .scroll::-webkit-scrollbar {
   width: 6px;
 } 

.nav {
   padding-left: 10px;
   border-left: 2px solid var(--main-color);
}

.body {
   line-height: 1.4rem;
}

.body p {
   margin-bottom: 20px;
   line-height: 1.3rem;
}

.body a {
   color: var(--link-color);
   text-decoration: none;
   font-weight: 600;
   transition: .3s;
}

.body a:hover {
   color: var(--main-color);
}

.body ul {
   list-style: none;
}

.body ul li {
   padding-left: 25px;
   margin-bottom: 15px;
   position: relative;
}

.body ul li::before {
   content: '';
   position: absolute;
   left: 0;
   top: 5px;
   height: 9px;
   width: 9px;
   border-radius: 50%;
   background-color: var(--main-color);
}

.body code {
   background-color: rgba(9, 59, 185, .7);
   border-radius: 0.1rem;
   -webkit-box-decoration-break: clone;
   box-decoration-break: clone;
   font-size: 16px;
   padding: 1px 4px;
   word-break: break-word;
   color: #fff;
}

.aside__btn {
   display: none;
   position: fixed;
   right: 0px;
   bottom: 0px;
   padding: 15px 10px;
   box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, .3);
   /* border-radius: 10px; */
   z-index: 3;
   transition: .3s;
   background-color: #fff;
   height: 50px;
   width: 100%;
}

.aside__btn span {
   width: 40px;
   height: 3px;
   border-radius: 10px;
   background-color: var(--link-color);
}

.aside__btn.close {
   opacity: 0;
   /* pointer-events: none; */
}

.aside__close {
   display: none;
}

@media (max-width: 1200px) {
   .inner {
      display: block;
   }
   .aside__close {
      display: block;
      position: absolute;
      right: 20px;
      top: 15px;
      font-size: 26px;
      color: var(--main-color);
      cursor: pointer;
   }
   .sidebar {
      position: fixed;
      left: 20px;
      top: 160px;
      transform: translateX(-200%);
      background-color: #fff;
      border-radius: 5px;
      z-index: 10;
      box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, .7);
      transition: .5s;
      padding: 60px 20px 20px 20px;
   }
   .sidebar.open {
      transform: translateX(0);
   }
   .aside__btn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
   }
}