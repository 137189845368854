.input {
   outline-color: var(--link-color);
   width: 100%;
   padding: 10px 15px;
   font-size: 1.2rem;
   font-family: inherit;
   border-radius: 10px;
   border: 2px solid var(--main-color);
   color: var(--dark-color);
   font-weight: 600;
}

.input::placeholder {
   font-weight: 600;
   color: var(--link-color);
   opacity: .8;
}