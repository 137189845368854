.inner {
   height: 100%;
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
   padding: 0px 10px;
   background: rgb(2,0,36);
   background: linear-gradient(31deg, rgba(2,0,36,1) 0%, rgba(255,255,255,1) 0%, rgba(130,162,255,1) 100%);
}

.form {
   padding: 50px;
   border: 2px solid var(--main-color);
   border-radius: 20px;
   width: 100%;
   max-width: 550px;
   background-color: #fff;
}

.password__inner {
   margin: 20px 0px;
}

.password__inner {
   position: relative;
}

.password__btn {
   position: absolute;
   right: 15px;
   top: 50%;
   transform: translateY(-50%);
   cursor: pointer;
}

.password__btn img {
   max-width: 30px;
}

.remember {
   margin-bottom: 20px;
}

.btn {
   width: 100%;
   padding: 15px;
   font-size: 1.5rem;
   border: 2px solid var(--main-color);
   background-color: var(--main-color);
   color: #fff;
   border-radius: 10px;
   margin-bottom: 30px;
   cursor: pointer;
   transition: .3s;
}

.btn:hover {
   color: var(--main-color);
   background-color: transparent; 
}

.btn:disabled {
   cursor: not-allowed;
   background-color: var(--disable-color);
   border-color: var(--disable-color);
   color: var(--disable-text);
}

input.form__input {
   max-width: 500px;
   width: 100%;
   display: block;
   padding-right: 50px;
   margin: 0 auto;
   transition: .3s;
   margin-bottom: 15px;
}

input.form__input:last-child {
   margin-bottom: 0;
}

.error {
   display: flex;
   align-items: center;
   margin-top: 10px;
   justify-content: center;
   padding: 10px 10px;
   background-color: #FF6F6F;
   color: #fff;
   border-radius: 5px;
}

.error__text {
   margin-right: 10px;
   font-size: 1rem;
   font-weight: 600;
}

.tooltip__icon {
   max-width: 25px;
}

.btn:disabled {
   cursor: not-allowed;
   background-color: var(--disable-color);
   border-color: var(--disable-color);
   color: var(--disable-text);
}

input.form__input.invalid {
   border-color: #FF6F6F;
   outline: none;
}

.tooltiparea {
   max-width: 350px;
   width: 100%;
   padding: 10px;
   font-size: 1rem
}