.footer__column-title {
   font-size: 1.5rem;
   color: #fff;
   margin-bottom: 20px;
}

.footer__column-list {
   list-style: none;
}

.footer__item {
   margin-bottom: 10px;
}

.footer__link {
   text-decoration: none;
   color: var(--footer-text);
   transition: .3s;
}

.footer__link:hover {
   color: var(--link-color);
}

.footer__column-accardion {
   width: 100%;
   display: none;
   padding: 20px;
   text-align: left;
   color: #fff;
   cursor: pointer;
   transition: .3s;
   position: relative;
}


.footer__column-accardion:hover .footer__accardion-title {
   color: var(--link-color);
}

.footer__column-accardion:hover .footer__accardion-icon {
   color: var(--link-color);
}

.footer__accardion-icon {
   font-size: 2rem;
   transition: .3s;
   position: absolute;
   right: 10px;
   top: 50%;
   transform: translateY(-50%);
}

.footer__accardion-title {
   padding-right: 60px;
   font-size: 1.5rem;
   color: #fff;
   transition: .3s;
   text-transform: uppercase;
}

@media (max-width: 990px) {
   .footer__column-title {
      display: none;
   }
   .footer__column-accardion {
      display: block;
   }
   .footer__column {
      /* max-width: 500px; */
      width: 100%;
      border-bottom: 2px solid var(--footer-text);
   }

   .footer__column:last-child {
      border-bottom: none;
   }

   .footer__column-list {
      max-height: 0;
      overflow: hidden;
      transition: all .3s cubic-bezier(0,1,0,1);
      text-align: left;
      padding-left: 20px;
   }

   .footer__item:last-child {
      margin-bottom: 20px;
   }
   .footer__column-list.open {
      height: auto;
      max-height: 99999px;
      transition: all .3s cubic-bezier(1,0,1,0);
   }
}

@media (max-width: 600px) {
   .footer__accardion-title {
      font-size: 1.25rem;
   }
}
