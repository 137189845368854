.contacts {
   height: 100%;
}

.inner {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 100px;
   align-items: center;
   height: 100%;
}

.info {
   padding-right: 30px;
}

.map {
   height: 100%;
   position: relative;
}

.map iframe {
   position: absolute;
   top: 0;
   border: 0;
   width: 100%;
   height: 100%;
}

.map::after {
   content: '';
   position: absolute;
   height: 100%;
   width: 80px;
   background: url('../../assets/img/round-corner.svg');
   right: 0;
   top: 0;
}

.title {
   font-size: 3rem;
   margin-bottom: 30px;
   text-transform: uppercase;
   color: var(--main-color);
}

.label {
   text-transform: uppercase;
   color: var(--dark-color);
   margin-bottom: 10px;
   letter-spacing: 1px;
}

.email {
   margin-bottom: 40px;
}

.additional {
   display: flex;
}

.additional__item {
   margin-right: 60px;
}

.additional__item:last-child {
   margin-right: 0px;
}

.link {
   color: var(--link-color);
   text-decoration: none;
   transition: .3s;
   font-weight: 600;
   position: relative;
}

.link::after {
   position: absolute;
   content: '';
   width: 0%;
   left: 0;
   bottom: -3px;
   height: 2px;
   background-color: var(--dark-color);
   transition: .3s;
}



.link:hover {
   color: var(--dark-color);
}

.link:hover::after {
   width: 100%;
}

.text {
   color: var(--text-color);
   margin-bottom: 50px;
   max-width: 600px;
}

.container {
   max-width: 1920px;
   width: 100%;
   margin: 0 auto;
   height: 100%;
}

@media (max-width: 1250px) {
 .inner {
   grid-gap: 60px;
 }  
}

@media (max-width: 1100px) {
   .title {
     font-size: 2.5rem;
   }  
}

@media (max-width: 900px) {
   .inner {
     grid-template-columns: 1fr;
     grid-gap: 40px;
   }
   .info {
      order: -1;
      padding: 60px 20px 0px;
   }  
   .title {
      text-align: center;
   }

   .text {
      text-align: center;
      margin: 0 auto 30px;
   }
   .email {
      text-align: center;
   }
   .additional {
      flex-direction: column;
   }

   .additional__item {
      text-align: center;
   }

   .additional__item {
      margin-right: 0;
      margin-bottom: 40px;
   }

   .additional__item:last-child {
      margin-bottom: 0;
   }

   .map {
      height: 400px;
   }
   .map::after {
      display: none;
   }
}

@media (max-width: 650px) {
   .text {
      font-size: 1rem;
   }
}

@media (max-width: 470px) {
   .title {
      font-size: 2rem;
   }
}