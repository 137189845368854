.header__mobmenu {
   display: none;
   position: fixed;
   width: 370px;
   height: 100%;
   right: 0;
   top: 0;
   background-color: rgb(255, 255, 255);
   /* box-shadow: -2px 0px 7px 4px rgba(14, 14, 14, 0.2); */
   box-shadow: 0 1.5625rem 2.8125rem rgba(30, 30, 42, 0.08);
   padding: 15px;
   transform: translateX(200%);
   transition: .3s;
   z-index: 999;
}

.header__mobmenu.open {
   transform: translateX(0%);
}

.close {
   position: absolute;
   right: 20px;
   top: 20px;
   font-size: 36px;
   color: var(--main-color);
   cursor: pointer;
}

.header__mob-list {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-top: 60px;
   height: 100%;
}

.header__mob-list li {
   border-bottom: 2px solid var(--link-color);
   width: 100%;
   text-align: center;
}

.header__mob-list li:last-child {
   border-bottom: none;
}

.header__mob-list li a {
   color: var(--text-color);
   text-decoration: none;
   font-size: 1.5rem;
   padding: 25px 0px;
   display: block;
}

li.sigin {
   border-bottom: none;
   margin-bottom: 20px;
}

li.sigin .sigin__btn {
   background-color: var(--link-color);
   border-radius: 10px;
   color: #fff;
   font-size: 26px;
   font-weight: 700;
   border: 2px solid var(--main-color);
}

li.lang {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 20px;
   border-bottom: none;
   margin-bottom: 30px;
}

.lang__btn {
   display: flex;
   align-items: center;
   width: 100%;
   justify-content: center;
   padding: 10px 15px;
   border: 2px solid var(--main-color);
   border-radius: 10px;
   font-weight: 700;
   color: var(--main-color);
   cursor: pointer;
}

.lang__btn.current {
   background-color: var(--main-color);
   color: #fff;
}

.lang__btn img {
   max-width: 30px;
   width: 100%;
   margin-right: 10px;
}


@media (max-width: 1300px) {
   .header__mobmenu {
      display: block;
   }
}

@media (max-width: 550px) {
   .header__mobmenu {
      width: 310px;
   }

   span.lang__btn {
      padding: 10px 20px;
      font-size: 16px;
   }

   li.lang {
      grid-gap: 10px
   }
}
